<template>
    <div>
        <NavigationCommon activeIndex="6"></NavigationCommon>
        --关于我们--
        <FooterCommon activeIndex="6"></FooterCommon>
    </div>
</template>
<script>
import NavigationCommon from '@/components/Navigation.vue';
import FooterCommon from '@/components/Footer.vue';
export default {
    name: 'AboutUs',
    components: {
        NavigationCommon,
        FooterCommon
    },
    data() {

    },
    methods: {

    }
}
</script>
<style scoped>
</style>