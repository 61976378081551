<template>
    <div>
        <NavigationCommon activeIndex="5"></NavigationCommon>
        <div class="content">
            <div class="content-left">
                <el-menu mode="horizontal" :default-active="currentIndex" @Select="menuSelect" >
                    <el-menu-item index="1">
                        月报
                    </el-menu-item>
                    <el-menu-item index="2">
                        历年年度报告
                    </el-menu-item>
                    <el-menu-item index="3">
                        规章制度
                    </el-menu-item>
                    <el-menu-item index="4">
                        专项基金
                    </el-menu-item>
                </el-menu>
            </div>
            <el-card class="content-right">
                <template #header>
                    <div class="card-header">
                        <span class="cardTitle">信息披露</span>
                    </div>
                </template>
                <div>
                    <div class="dongtaiRow" v-for="item in tableData" :key="item.id">
                        <div class="dongtaiImg">
                            <img :src="item.mainImgUrl" class="dongtaiImgTab"/>
                        </div>
                        <div class="dongtaiTitle">
                            <a v-if="item.content" :href="'/detail?id=' + item.id " target="_blank">
                                <p><span class="titleContent">{{ item.title }}</span></p>
                                <p><span class="titleDate">{{ item.publishDate }}</span></p>
                            </a>
                            <a v-if="!item.content" :href="item.linkUrl" target="_blank">
                                <p><span class="titleContent">{{ item.title }}</span></p>
                                <p><span class="titleDate">{{ item.publishDate }}</span></p>
                            </a>
                        </div>
                    </div>
                    <div class="page">
                        <el-pagination background layout="prev, pager, next" class="pageContent" @current-change="handleCurrentChange" :page-size="pageSize" :total="total" :current-page="pageNo"/>
                    </div>
                </div>
                
            </el-card>
        </div>
        <FooterCommon activeIndex="5"></FooterCommon>
    </div>
</template>

<script>
import NavigationCommon from '@/components/Navigation.vue';
import FooterCommon from '@/components/Footer.vue';
import { informationShow } from '@/api/cmsApi';
export default {
    name: 'InformationShow',
    components: {
        NavigationCommon,
        FooterCommon
    },
    data() {
        return {
            tableData: [],
            pageNo: 1,
            pageSize: 5,
            total: 0,
            currentIndex: 1
        }
    },
    methods: {
        init() {
            this.query()
        },
        query() {
            let param = {'pageNo': this.pageNo, 'pageSize': this.pageSize, 'type': this.currentIndex}
            informationShow(param).then(res => {
                if (res.code === 200) {
                    this.tableData = res.data.records
                    this.total = res.data.total
                }
            })
        },
        handleCurrentChange(currentPage) {
            this.pageNo = currentPage; //每次点击分页按钮，当前页发生变化
            this.query()
        },
        menuSelect(index) {
            this.currentIndex = index
            this.pageNo = 1
            this.init()
        }
    },
    created() {
        this.init()
    }
}
</script>

<style scoped>
    .cardTitle {
        font-weight: bold;
        font-size: xx-large;
        color: red;
    }
    .content {
        max-width: 1140px;
        margin: 0 auto;
    }
    .page {
        margin-top: 10px;
    }
    .pageContent {
        justify-content: center;
    }
    .dongtaiRow {
        height: 240px;
        display: flex;
        margin-top: 20px;
    }
    .dongtaiImg {
        width: 32%;
    }
    .dongtaiImgTab {
        width: 245px;
        height: 170px;
    }
    .dongtaiTitle {
        width: 60%;
        font-size: x-large;
        text-align: left;
        margin-left: 30px;
        margin-top: 50px;
    }
    .titleContent {
        width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .titleDate {
        font-size: 16px;
    }
    a {
        text-decoration: none;
    }
    .content-left {
        height: 100%;
    }
    .content-right {
        height: 100%;
    }
</style>