<template>
    <div class="div">
        <!--菜单-->
        <div class="div-item menu">
            <el-menu :default-active="currentIndex" :ellipsis="false" @Select="handleSelect">
                <el-menu-item index="1"><RouterLink to="/">首页</RouterLink></el-menu-item>
                <el-menu-item index="2"><RouterLink to="/charityProject">公益项目</RouterLink></el-menu-item>
                <el-menu-item index="3"><RouterLink to="/organDynamic">机构动态</RouterLink></el-menu-item>
                <el-menu-item index="4"><RouterLink to="/industryInformation">行业资讯</RouterLink></el-menu-item>
                <el-menu-item index="5"><RouterLink to="/informationShow">信息披露</RouterLink></el-menu-item>
                <!--<el-menu-item index="6"><RouterLink to="/aboutUs">关于我们</RouterLink></el-menu-item>-->
            </el-menu>
        </div>
        <!--企业信息-->
        <div class="div-item">
            <ul class="ul companyInfo">
                <li><span>地址：</span>苏州工业园区通园路236号苏印智造2005室</li>
                <li><span>联系电话：</span>18260176576</li>
                <li><span>版权所有: </span>苏州市爱心达人公益慈善基金会</li>
            </ul>
        </div>
        <!--二维码-->
        <div class="div-item qrcode">
            <img class="img" src="/qrcode.jpg" />
        </div>
    </div>
</template>
<script>
    export default {
        name: 'FooterCommon',
        props: {
            activeIndex: String
        },
        data() {
            return {
                currentIndex: this.activeIndex
            }
        },
        methods: {
            handleSelect(key, keyPath) {
                console.log('选中的是',key, keyPath);
                this.currentIndex = key;
                console.log(key)   
            }
        }
    }
</script>
<style scoped>
.div {
    max-width: 1140px;
    margin: 0 auto;
}
.div-item {
    display: inline-block;
}
.menu {
    float: left;
    height: 300px;
}
.qrcode {
    float: right;
}
.companyInfo {
    margin-top: 130px;
}
.ul li {
    list-style-type: none;
    font-size: 15px;
    line-height: 32px;
    text-align: left;
    margin: 20 auto;
}
.img {
    margin-top: 130px;
    height: 140px;
}
</style>